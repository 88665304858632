import React, { useEffect, useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import loadable from "@loadable/component";
import ScrollAnimation from 'react-animate-on-scroll';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { sliderSettings } from "../SliderSettings/SliderSettings";
import PropertyCardSlide from "../PropertyCardSlide/PropertyCardSlide";
import { ApiRequest } from "../../common/utils/api_request_utils";
import './assets/styles/_index.scss';
import { PageLinks } from "../../common/site/page-static-links";

const Slider = loadable(() => import("react-slick"));

const AreaguideSimilarProperties = (props) => {
    const [propItems, setPropItems] = useState([])
    const [letpropItems, setLetPropItems] = useState([])
    const [activeTab, setActiveTab] = useState('property-for-sale')

    useEffect(() => {
        ApiRequest({
            method: "GET",
            url: `${process.env.GATSBY_STRAPI_SRC}/api/properties?filters[display_address][$contains]=${props.areaname}&filters[department][$eq]=residential&filters[search_type][$eq]=sales&pagination[pageSize]=8`,
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`
            }
        }, (result) => {
            if (result) {
                setPropItems(result.data)
            }
        })

        ApiRequest({
            method: "GET",
            url: `${process.env.GATSBY_STRAPI_SRC}/api/properties?filters[display_address][$contains]=${props.areaname}&filters[department][$eq]=residential&filters[search_type][$eq]=lettings&pagination[pageSize]=8`
        }, (result) => {
            if (result) {
                setLetPropItems(result.data)
            }
        })
    }, []);


    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    content {
                        areaguide_detail_property_module {
                            title
                            cta_title
                        }
                    }
                }
            }
        }
    `)

    const contentData = data?.site?.siteMetadata?.content?.areaguide_detail_property_module

    
    let view_all_url = PageLinks.results_sales

    if (activeTab === "property-to-rent") {
        view_all_url = PageLinks.results_lettings
    }


    return (
        <>
            {((propItems && propItems.length > 0) || (letpropItems && letpropItems.length > 0)) &&

                <section className="featured-properties-wrapper section-p-120 area-guides">
                    <Container>
                        <Row>
                            <Col lg={6} md={8}>
                                <ScrollAnimation animateIn="animate__slideInUp" delay={600} animateOnce offset={10}>
                                    <h2>{`${contentData.title} ${props.areaname}`}</h2>
                                </ScrollAnimation>
                            </Col>
                            {contentData.cta_title &&
                                <Col lg={6} md={4} className="d-md-flex justify-content-end d-none">
                                    <ScrollAnimation animateIn="animate__slideInUp" delay={600} animateOnce offset={10}>
                                        <Link to={`/${view_all_url}/`} className="button button-primary-outline-transparent">{contentData.cta_title}</Link>
                                    </ScrollAnimation>
                                </Col>
                            }
                        </Row>

                        <Row>
                            <Col>
                                <ScrollAnimation animateIn="animate__slideInUp" delay={600} animateOnce offset={10}>
                                    <Tabs
                                        defaultActiveKey="property-for-sale"
                                        className="property-slider-tabs"
                                    >
                                        {propItems.length > 0 &&
                                            <Tab eventKey="property-for-sale" title="Property for Sale">
                                                <Slider className="property-slider" {...sliderSettings}>
                                                    {propItems.map((property, i) => {

                                                        let details_path = '/property-for-sale'
                                                        let processedImages = JSON.stringify({});

                                                        if (property.attributes?.imagetransforms?.images_Transforms) {
                                                            processedImages = property.attributes?.imagetransforms?.images_Transforms;
                                                        }

                                                        return (
                                                            <PropertyCardSlide
                                                                {...property.attributes}
                                                                details_path={details_path}
                                                                img={property?.attributes?.images}
                                                                processedImages={processedImages}
                                                                id={property?.attributes?.crm_id}
                                                                strapi_id={property.id}
                                                            />
                                                        )
                                                    })
                                                    }
                                                </Slider>
                                                <div className="d-md-none property-slider-btn">
                                                    <Link to={`/${view_all_url}/`} className="button button-primary-outline-transparent">{contentData.cta_title}</Link>
                                                </div>
                                            </Tab>
                                        }
                                        {letpropItems.length > 0 &&
                                            <Tab eventKey="property-to-rent" title="Property to Rent">
                                                <Slider className="property-slider" {...sliderSettings}>
                                                    {letpropItems.map((property, i) => {

                                                        let details_path = '/property-to-rent'
                                                        let processedImages = JSON.stringify({});

                                                        if (property.attributes?.imagetransforms?.images_Transforms) {
                                                            processedImages = property.attributes?.imagetransforms?.images_Transforms;
                                                        }

                                                        return (
                                                            <PropertyCardSlide
                                                                {...property?.attributes}
                                                                index={i}
                                                                details_path={details_path}
                                                                img={property?.attributes?.images}
                                                                processedImages={processedImages}
                                                                id={property?.attributes?.crm_id}
                                                                strapi_id={property.id}
                                                            />
                                                        )
                                                    })
                                                    }
                                                </Slider>
                                                <div className="d-md-none property-slider-btn">
                                                    <Link to={`/${view_all_url}/`} className="button button-primary-outline-transparent">{contentData.cta_title}</Link>
                                                </div>
                                            </Tab>
                                        }
                                    </Tabs>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                    </Container>
                </section>
            }
        </>
    )
}

export default AreaguideSimilarProperties