import React from 'react'
import loadable from "@loadable/component";

import Seo from "../components/seo"
import Layout from '../components/layout'
import { graphql } from 'gatsby';
import { PageLinks } from '../common/site/page-static-links';
import AreaguideSimilarProperties from '../components/SimilarProperties/AreaguideSimilarProperties';

const StaticIntro = loadable(() => import("../components/StaticIntro/StaticIntro"));
const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const PropertyDetailsMap = loadable(() => import("../components/PropertyDetailsMap/PropertyDetailsMap"));
const SimilarProperties = loadable(() => import("../components/SimilarProperties/SimilarProperties"));
const AreaguideDetailDesc = loadable(() => import("../components/AreaguideDetailDesc/AreaguideDetailDesc"));
const CtaModule = loadable(() => import("../components/CtaModule/CtaModule"));
const AreaguideDetailsTemplate = ({ data }, props) => {
    const areaguideData = data?.strapiAreaGuide

    const bannerCTAS = {
        cta_1_title: "Property for Sale",
        cta_1_link: {
            link_type: "internal",
            slug: `property/for-sale/in-${areaguideData.slug}`,
            title: "Property for Sale",
        },
        cta_2_title: "Property to Rent",
        cta_2_link: {
            link_type: "internal",
            slug: `property/to-rent/in-${areaguideData.slug}`,
            title: "Property to Rent",
        }
    }


    let breadcrumData;

    if (PageLinks?.areaguide_parent_label) {
        breadcrumData = { parentlabel: PageLinks.areaguide_parent_label, parentlink: PageLinks.areaguide_parent, subparentlabel: PageLinks.areaguide_label, subparentlink: PageLinks.areaguide, title: areaguideData.title }
    } else {
        breadcrumData = { parentlabel: PageLinks.areaguide_label, parentlink: PageLinks.areaguide, title: areaguideData.title }
    }

    return (
        <Layout popularSearch={"Generic pages"}>
            <div className="layout-padding-top"></div>
            <BreadcrumbModule {...breadcrumData} />
            <div className='layout-grey-bg-theme areaguide-detail-wrapper'>
                <StaticIntro
                    {...module}
                    headingClass="secondary"
                    title={areaguideData.title}
                    content={{ data: { content: areaguideData?.banner_content?.data?.banner_content } }}
                    staticClass="static-page-intro"
                    cta_1_title={bannerCTAS.cta_1_title}
                    cta_1_link={bannerCTAS.cta_1_link}
                    cta_2_title={bannerCTAS.cta_2_title}
                    cta_2_link={bannerCTAS.cta_2_link}
                />

                <AreaguideDetailDesc add_content={areaguideData.add_content} id={areaguideData.strapi_id} imagetransforms={areaguideData.imagetransforms} author={areaguideData?.select_team}/>

                {(areaguideData.latitude && areaguideData.longitude) &&
                    <PropertyDetailsMap
                        lat={areaguideData.latitude}
                        lng={areaguideData.longitude}
                    />
                }
                <AreaguideSimilarProperties
                    title={`Featured properties in ${areaguideData.title}`}
                    areaname={areaguideData.title}
                />
            </div>
            <CtaModule />
        </Layout>
    )
}

export const Head = ({ data }) => {
    const areaguideData = data?.strapiAreaGuide
    return (
        <Seo
            title={areaguideData?.seo?.metaTitle ? areaguideData?.seo?.metaTitle : areaguideData?.title}
            description={areaguideData?.seo?.metaDescription ? areaguideData?.seo?.metaDescription : areaguideData?.title}
        />
    )
}

export default AreaguideDetailsTemplate

export const query = graphql`
query ($page_id: Int) {
    strapiAreaGuide(strapi_id: {eq: $page_id}) {
        ...AreaGuideFragment
        select_team {
            ...TeamFragment
        }
        latitude
        longitude
        add_content {
            ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
                ...PlainContentFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE {
                ...ImageFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
                ...AccordionFragment
            }
        }
    }
}`